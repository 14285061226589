import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import {
  Section,
  SectionTitle,
  SectionDescription,
  SectionHeader,
  SectionPageTitle,
  BreadCrumb,
} from "../components/section";
import Layout from "../components/layout";
import SEO from "../components/seo";
import DefaultButton from "../components/button/DefaultButton";
import PhoneIcon from "../components/Icons/PhoneIcon";
import generateHTML from "../utils/generateHTML";
import Content, { HTMLContent } from "../components/Content";
import BuildingRoofStyle from "../sections/Product/BuildingRoofStyle";
import BreakpointUp from "../components/Media/BreakpointUp";

const CtaWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin: 0 -30px;
  padding: 30px 0;
  ${BreakpointUp.lg`		
		flex-direction: inherit;
		text-align:left;
	`}
`;
const TextWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 30px;
  ${BreakpointUp.lg`		
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
	`}

  h3 {
    margin-bottom: 5px;
  }
  p {
    ${BreakpointUp.lg`	
			font-size:18px;
			line-height:30px;			
		`}
  }
`;
const ButtonWrap = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 30px;
  ${BreakpointUp.lg`		
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	`}
  & a {
    display: block;
    .btn {
      padding: 15px 30px;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const MetalBuildingRoofStylesPage = ({ data }) => {
  const pageData = data.contentfulMetalBuildingRoofsPage;
  const PostContent = HTMLContent || Content;
  return (
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <SectionHeader
        pt="140px"
        pb="90px"
        xpt="110px"
        xpb="60px"
        bgColor="#F4F8FC"
      >
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link>/<span>Roof Guide</span>
          </BreadCrumb>
          <SectionPageTitle mt="30px" mb="15px" textAlign="center">
            {pageData.title}
          </SectionPageTitle>
          <SectionDescription maxWidth="1120px">
            <PostContent
              content={generateHTML(
                pageData.heroDescription.childMarkdownRemark.html
              )}
            />
          </SectionDescription>
        </div>
      </SectionHeader>
      <Section pt="90px" pb="10px" xpt="60px" xpb="60px" bg="#fff">
        <div className="container">
          <SectionTitle mb="20px">{pageData.roofsSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1120px">
            {
              pageData.childContentfulMetalBuildingRoofsPageFeaturesJsonNode
                .roofDescription
            }
          </SectionDescription>
          <BuildingRoofStyle data={pageData.metalRoofs} />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
        <SectionDescription maxWidth="1120px" textAlign="left">
          <PostContent
            content={generateHTML(
              pageData.featuresSectionDescription.childMarkdownRemark.html
            )}
          />
        </SectionDescription>
      </Section>
      <Section pt="0px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
        <div className="container">
          <CtaWrap>
            <TextWrap>
              <h3>{pageData.ctaTitle}</h3>
              <SectionDescription maxWidth="1120px" textAlign="left">
                <PostContent
                  content={generateHTML(
                    pageData.ctaDescription.childMarkdownRemark.html
                  )}
                />
              </SectionDescription>
            </TextWrap>
            <ButtonWrap>
              <a href="tel:8773505464">
                <DefaultButton icon={<PhoneIcon />} text="Call Now" />
              </a>
            </ButtonWrap>
          </CtaWrap>
        </div>
      </Section>
    </Layout>
  );
};

export default MetalBuildingRoofStylesPage;

export const pageQuery = graphql`
  query MetalBuildingRoofStylesPageQuery {
    contentfulMetalBuildingRoofsPage {
      metaTitle
      metaDescription
      title
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      roofsSectionTitle
      metalRoofs {
        roofName
        metalRoofName
        metalRoofDescription {
          childMarkdownRemark {
            html
          }
        }
        metalRoofBenefits {
          content
        }
        metalRoofImage {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
      ctaTitle
      ctaDescription {
        childMarkdownRemark {
          html
        }
      }
      childContentfulMetalBuildingRoofsPageFeaturesJsonNode {
        roofDescription
      }
      featuresSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
