import React from 'react'
import styled from "styled-components"
import Img from 'gatsby-image'
import BreakpointUp from "../../components/Media/BreakpointUp"
import generateHTML from '../../utils/generateHTML';
import Content, { HTMLContent } from '../../components/Content';
import ListArrow from "../../images/list-arrow.svg"

const SectionWrap = styled.div`
`
const SectionItem = styled.div`  
  display:flex;	
  flex-wrap:wrap;
  align-items:center;
  margin:0 -15px;
  padding:20px 0;
  ${BreakpointUp.lg`        
    padding:30px 0;
    margin:0 -20px;
  `}
  ${BreakpointUp.xl`        
    padding:50px 0;
    margin:0 -45px;
  `}
  &:nth-child(even){
      ${BreakpointUp.lg`        
        flex-direction: row-reverse;
      `}
    }
`

const SectionFigure = styled.div`  
  width:100%;
  position:relative;
  padding: 0 15px 30px 15px;
  ${BreakpointUp.lg`        
    padding:0 20px;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`  
    padding: 0 45px;
	`}
`


const SectionContent = styled.div`  
  position:relative;
  width:100%;
  padding: 0 15px;
  ${BreakpointUp.lg`        
    padding:0 20px;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  ${BreakpointUp.xl`  
    padding: 0 45px;
	`}
  & h2{
    margin-bottom: 15px;
    color:#002337;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 992px) {
      font-size: 34px;
      line-height: 44px;
    }
  }
  & strong{
    display:block;
    font-family: 'Fira Sans', serif;
    color: #002337;
    font-weight: 700;
    margin:0 0 10px;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 992px) {
      font-size: 28px;
      line-height: 38px;
    }
  }
  & ul{
		list-style: none;
		margin:0 0 30px;
		padding:0;
		& li {
			padding-left:20px;
			vertical-align: text-top;
			color:#231F20;
			font-weight:400;
			margin:0;
			line-height: 28px;
			+ li{
				margin-top:5px;
			}
			&:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			}
		}
	}
`

const Card = styled.div`
	display:flex;
	flex-direction:column;
  position:relative;
`
const CardFigure = styled.div`	
	position:relative;
	width:100%;
	& .gatsby-image-wrapper{
		height:100%;
	}
`
const CardTip = styled.div`
	background-color:${props=>props.bgColor};
	color:${props=>props.color};
	font-size:14px;
	line-height:18px;
	font-weight:700;
	padding:5px 15px;
	position:absolute;
  left:${props=>props.left};  
  right:${props=>props.right};  
	z-index:1;
  top:50px; 
  
`
CardTip.defaultProps = {
	color:"#2F9800",
  bgColor:"rgba(47, 152, 0, 0.14)"
}


const BuildingRoofStyle = ({ data }) => {
  const PostContent = HTMLContent || Content;
	return(
		<SectionWrap>
      {
        data.map((item, i)=> {
          return(
            <SectionItem>
              <SectionFigure className="SectionFigure">
                <Card className="card">
                  {item.roofName==="Vertical Roof"? (<CardTip right="0" color="#2F9800" bgColor="#d5eacc">Best</CardTip>):(<></>)}
                  {item.roofName==="A-Frame Roof"? (<CardTip  color="#216ABC" bgColor="#d3e1f2">Better</CardTip>):(<></>)}
                  {item.roofName==="Regular Roof"? (<CardTip right="0" color="#DE5600" bgColor="#f8ddcc">Good</CardTip>):(<></>)}
                  <CardFigure>
                    <Img fluid={item.metalRoofImage.fluid} />
                  </CardFigure>
                </Card>
              </SectionFigure>          
              <SectionContent>
                <h2>{item.metalRoofName}</h2>
                <PostContent
                  content={generateHTML(item.metalRoofDescription.childMarkdownRemark.html)}
                />
                <strong>Benefits</strong>
                <ul>
                  {
                    item.metalRoofBenefits.map(item=>{
                      return(
                        <li>{item.content}</li>
                      )
                    })
                  }
                </ul>
              </SectionContent>
            </SectionItem>     
          )
        })
      }
    </SectionWrap>
	)
}

export default BuildingRoofStyle